// IndentEntries.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import IndentEntryDataService from '../services/IndentEntryService';
import InvoicePDF from './InvoicePDF'; // Import the InvoicePDF component
import { formatNumberKGPlain } from './utils';

const IndentEntries = () => {
  const { code: indentCode } = useParams();
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    const fetchEntries = async () => {
      try {
        const response = await IndentEntryDataService.findIndentEntries(indentCode);
        setEntries(response.data);
      } catch (error) {
        console.error('Error fetching entries:', error);
      }
    };
    fetchEntries();
  }, [indentCode]);

  const firstEntry = entries && entries.length > 0 ? entries[0] : null;

  const comment = firstEntry && firstEntry.indent ? firstEntry.indent.comment : '';

  // Extracting indent type and customer information
  const indentType = firstEntry && firstEntry.indent ? firstEntry.indent.indentType : '';
  const customer = firstEntry ? firstEntry.customer : null;

  const totalCost = entries
    ? entries.reduce((acc, item) => acc + item.finalPriceForGivenQuantity, 0)
    : 0;

  const totalWeight = entries
    ? entries.reduce((acc, item) => acc + item.articleWeightForGivenQuantity, 0)
    : 0;

  const totalNumberOfPackages = entries
    ? entries.reduce((acc, item) => acc + item.numberOfPackages, 0)
    : 0;

  // Calculate Total Real Quantity (for the PDF invoice)
  const totalRealQuantity = entries.reduce(
    (total, entry) => total + (entry.realQuantity ? parseFloat(entry.realQuantity) : 0),
    0
  );

  const columns = [
    { Header: '#', accessor: 'id' },
    { Header: 'Artikal', accessor: 'article.name' },
    /*  { Header: 'Cena', accessor: 'articleWholeSalePrice' },
     { Header: 'Valuta', accessor: 'customer.paymentCurrency' }, */
    { Header: 'Jedinica Mere', accessor: 'article.unitOfMeasurement' },
    { Header: 'Količina', accessor: 'requestedQuantity' },
    /*  { Header: 'Rabat', accessor: 'articleBrandDiscount' },
     { Header: 'Ukupna Cena', accessor: 'finalPriceForGivenQuantity' },
     { Header: 'Broj Paketa', accessor: 'numberOfPackages' }, */
    { Header: 'Težina', accessor: 'articleWeightForGivenQuantity' },
    // Removed "Realna Količina" from the columns displayed on the web page
  ];

  return (
    <div className="container mx-auto p-6">
      <h3 className="flex items-center text-2xl font-semibold text-gray-700">
        <i className="fas fa-shopping-cart mr-2 text-blue-500"></i>
        Porudžbina {indentCode}
      </h3>
      <div id="pdf-content">
        {/* Customer and Indent Type Information */}
        <div className="my-4">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h4 className="text-xl font-semibold text-gray-700 mb-2">
              Informacije o kupcu
            </h4>
            {customer ? (
              <div className="text-gray-700 space-y-1">
                <p>
                  <strong>Naziv:</strong> {customer.nameOfTheLegalEntity}
                </p>
                <p>
                  <strong>Kontakt osoba:</strong> {customer.contactPerson}
                </p>
                <p>
                  <strong>Broj telefona:</strong> {customer.contactNumber}
                </p>
                <p>
                  <strong>Email:</strong> {customer.email}
                </p>
                <p>
                  <strong>Adresa:</strong> {customer.address}, {customer.city}
                </p>
              </div>
            ) : (
              <p>Nema dostupnih informacija o kupcu.</p>
            )}
          </div>
        </div>
        <div className="my-4">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h4 className="text-xl font-semibold text-gray-700 mb-2">Način plaćanja</h4>
            <p className="text-gray-700">
              {indentType
                ? indentType === 'GOTOVINSKI_RACUN'
                  ? 'GOTOVINSKI RACUN'
                  : indentType.replace(/_/g, ' ')
                : 'Nije dostupno'}
            </p>
          </div>
        </div>


        {/* Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {columns.map((column) => (
                  <th
                    key={column.Header}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {column.Header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {entries.map((entry, index) => (
                <tr key={entry.id}>
                  <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {entry.article.name}
                  </td>
                  {/* <td className="px-6 py-4 whitespace-nowrap">
                    {formatNumberPlain(entry.articleWholeSalePrice)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {entry.customer.paymentCurrency}
                  </td> */}
                  <td className="px-6 py-4 whitespace-nowrap">
                    {entry.article.unitOfMeasurement}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {entry.requestedQuantity}
                  </td>
                  {/* <td className="px-6 py-4 whitespace-nowrap">
                    {`${entry.articleBrandDiscount} %`}
                  </td> */}
                  {/* <td className="px-6 py-4 whitespace-nowrap">
                    {formatNumberPlain(entry.finalPriceForGivenQuantity)}
                  </td> */}
                  {/* <td className="px-6 py-4 whitespace-nowrap">
                    {entry.numberOfPackages}
                  </td> */}
                  <td className="px-6 py-4 whitespace-nowrap">
                    {formatNumberKGPlain(entry.articleWeightForGivenQuantity)}
                  </td>
                  {/* Removed the cell for "Realna Količina" */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Comment */}
        <div className="mt-4">
          <textarea
            className="block w-full px-4 py-2 border rounded-lg text-gray-700 bg-gray-50 border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Napomena..."
            value={comment}
            readOnly
          />
        </div>

        {/* Totals */}
        <div className="bg-white p-6 rounded-lg shadow-lg mt-4">
          <div className="space-y-4">
            {/* <div className="flex justify-between items-center">
              <strong className="text-lg text-gray-700">Ukupna Cena :</strong>
              <span className="text-lg text-gray-900 font-bold">
                {formatNumberPlain(totalCost)}
              </span>
            </div> */}
            {/* <div className="flex justify-between items-center">
              <strong className="text-lg text-gray-700">Ukupno Paketa :</strong>
              <span className="text-lg text-gray-900 font-bold">
                {totalNumberOfPackages}
              </span>
            </div> */}
            <div className="flex justify-between items-center">
              <strong className="text-lg text-gray-700">Ukupna Težina :</strong>
              <span className="text-lg text-gray-900 font-bold">
                {formatNumberKGPlain(totalWeight)}
              </span>
            </div>
            {/* Removed display of Total Real Quantity on the web page */}
          </div>
        </div>
      </div>

      {/* Print Button */}
      <div className="mt-4">
        <InvoicePDF
          indentCode={indentCode}
          entries={entries}
          indentType={indentType}
          customer={customer}
          /* totalCost={totalCost} */
          totalWeight={totalWeight}
          /* totalNumberOfPackages={totalNumberOfPackages} */
          totalRealQuantity={totalRealQuantity}
          comment={comment}
        />
      </div>
    </div>
  );
};

export default IndentEntries;
