import React, { useContext, useState, useEffect } from "react";
import "../styles/Basket.css";
import { ApplicationContext } from "./ApplicationContext";
import IndentEntryService from "../services/IndentEntryService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatNumber, formatNumberKG, formatNumberWithoutPostfix, priceWithPDV, discountedPrice } from './utils';
import log from "../services/logger";
import {
  addQuantity,
  removeQuantity
} from './utils'; // Adjust the path if necessary

function isValidQuantity(value, s, m) {
  value = Number(value);
  if (isNaN(value) || value < s) {
    return false;
  }

  const tolerance = 0.001;

  // Check if value is equal to starting quantity
  if (Math.abs(value - s) < tolerance) {
    return true;
  }

  // Check if value is divisible by minimum quantity
  if (value % m < tolerance || Math.abs(value % m - m) < tolerance) {
    return true;
  }

  return false;
}

function getValidQuantity(value, s, m) {
  value = Number(value);

  if (isNaN(value) || value < s) {
    return s;
  }

  if (Math.abs(value - s) < 0.001) {
    return s;
  }

  if (value >= s && (value % m < 0.001 || Math.abs(value % m - m) < 0.001)) {
    return value;
  }

  // Adjust upwards to the next multiple of m
  const n = Math.ceil(value / m);
  const newValue = n * m;

  if (newValue < s) {
    return s;
  }

  return Number(newValue.toFixed(2));
}

const Basket = () => {
  const { setBasketItems, basketItems, removeAllBasketItems, loggedInClient, removeBasketItem } = useContext(ApplicationContext);
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [comment, setComment] = useState("");
  const [missingItemQuantities, setMissingItemQuantities] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [isDeliveryAddressEditable, setIsDeliveryAddressEditable] = useState(true);

  useEffect(() => {
    if (loggedInClient && loggedInClient.deliveryAddressList) {
      if (loggedInClient.deliveryAddressList.length === 1) {
        setDeliveryAddress(loggedInClient.deliveryAddressList[0]);
        setIsDeliveryAddressEditable(false);
      } else {
        setIsDeliveryAddressEditable(true);
      }
    }
    const newMissingItemQuantities = validateItemQuantities(basketItems);
    setMissingItemQuantities(newMissingItemQuantities);
  }, [basketItems, loggedInClient]);

  const validateItemQuantities = (basketItems) => {
    let missingQuantities = {};
    let noccoItemsQuantity = 0;

    for (let item of basketItems) {
      if (item.article.brand.brandName === "NOCCO") {
        noccoItemsQuantity += item.quantity;
      }

      if (
        !isValidQuantity(
          item.quantity,
          item.article.startingQuantityDemand,
          item.article.minimumQuantityDemand
        )
      ) {
        const adjustedQuantity = getValidQuantity(
          item.quantity,
          item.article.startingQuantityDemand,
          item.article.minimumQuantityDemand
        );
        const missingQuantity = adjustedQuantity - item.quantity;

        missingQuantities[item.article.name] = {
          article: item.article,
          missingQuantity: missingQuantity,
        };
      }
    }

    // NOCCO specific validation
    if (noccoItemsQuantity % 24 !== 0 && noccoItemsQuantity > 0) {
      const noccoArticle = basketItems.find(
        (item) => item.article.brand.brandName === "NOCCO"
      ).article;
      missingQuantities["NOCCO"] = {
        article: noccoArticle,
        missingQuantity: 24 - (noccoItemsQuantity % 24),
      };
    }

    return missingQuantities;
  };

  const handleQuantityChange = (itemIndex, value) => {
    const article = basketItems[itemIndex].article;
    const startingQuantityDemand = article.startingQuantityDemand;
    const minimumQuantityDemand = article.minimumQuantityDemand;

    let adjustedQuantity;
    if (value === "") {
      adjustedQuantity = "";
    } else {
      adjustedQuantity = getValidQuantity(
        value,
        startingQuantityDemand,
        minimumQuantityDemand
      );
    }

    const updatedBasketItems = basketItems.map((item, i) => {
      if (i === itemIndex) {
        return { ...item, quantity: adjustedQuantity };
      }
      return item;
    });
    setBasketItems(updatedBasketItems);
  };

  const confirmOrder = async () => {
    setIsLoading(true);

    if (!paymentType) {
      toast.error('Molimo izaberite tip plaćanja!');
      setIsLoading(false);
      return;
    }

    if (basketItems && basketItems.length > 0) {
      const itemsToCreate = basketItems
        .filter((item) => item.quantity > 0)
        .map((item) => ({
          customerId: loggedInClient.customer_id,
          articleName: item.article.name,
          requestedQuantity: item.quantity,
          deliveryAddress,
        }));
      try {
        const response = await IndentEntryService.createIndentEntries(itemsToCreate, paymentType, comment);
        removeAllBasketItems();
        setComment("");
        setPaymentType("");
        toast.success('Uspešno kreirana porudžbina!');
      } catch (error) {
        log.info(error);
        toast.error('Neuspešno ažuriranje! ' + error);
      }
    }
    setIsLoading(false);
  };

  const brandDiscount = (brand) => {
    const brandName = brand.brandName;
    const discountForTheBrand = loggedInClient?.discounts?.find((item) => item.brand.brandName === brandName);
    return discountForTheBrand ? discountForTheBrand.discount : 0;
  };

  const articlePriceWithDiscount = (article) => {
    return (Number(article.retailPrice) * (1 - Number(brandDiscount(article.brand)) / 100)).toFixed(2);
  };

  const handleDeliveryAddressChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue) {
      const parsedDeliveryAddress = JSON.parse(selectedValue);
      setDeliveryAddress(parsedDeliveryAddress);
    } else {
      setDeliveryAddress("");
    }
  };

  const totalCost = basketItems
    ? basketItems.reduce((acc, item) => acc + articlePriceWithDiscount(item.article) * item.quantity, 0)
    : 0;

  const totalWeight = basketItems
    ? basketItems.reduce((acc, item) => {
      const brutoMass = parseFloat(item.article.brutoMass);
      const requestedQuantity = parseFloat(item.quantity);
      if (isNaN(brutoMass) || isNaN(requestedQuantity)) {
        return acc;
      }
      return acc + brutoMass * requestedQuantity;
    }, 0)
    : 0;

  const totalNumberOfPackages = basketItems
    ? basketItems.reduce((acc, item) => {
      const quantityPerTransportPackage = item.article.quantityPerTransportPackage;
      const requestedQuantity = parseFloat(item.quantity);
      if (isNaN(quantityPerTransportPackage) || isNaN(requestedQuantity)) {
        return acc;
      }
      return acc + requestedQuantity / quantityPerTransportPackage;
    }, 0)
    : 0;

  const getPDV = (basketItems) => {
    if (basketItems && basketItems.length > 0) {
      return basketItems[0].article.pdv;
    }
    return 0;
  };

  const pdv = getPDV(basketItems);

  const allItemsHaveValidQuantities = basketItems.every(
    (item) =>
      item.quantity > 0 &&
      isValidQuantity(
        item.quantity,
        item.article.startingQuantityDemand,
        item.article.minimumQuantityDemand
      )
  );

  return (
    <div className="container mx-auto p-6">
      <br />
      <ToastContainer />
      {isLoading && <div className="loading-animation"></div>}
      <h3 className="flex items-center text-2xl font-semibold text-gray-700">
        <i className="fas fa-shopping-cart mr-2 text-blue-500"></i>
        Korpa
      </h3>
      <div className="overflow-x-auto">
        <table className="table table-responsive table-striped table-bordered table-margin">
          <thead>
            <tr>
              <th className="hide-on-mobile">Redni Broj</th>
              <th>Šifra Artikla</th>
              <th>Artikal</th>
              <th>Količina</th>
              <th className="hide-on-mobile">Težina</th>
              <th>Akcije</th>
            </tr>
          </thead>
          <tbody>
            {basketItems.length > 0 ? (
              basketItems.map((item, index) => {
                const article = item.article;
                const currentQuantity = item.quantity || "";

                return (
                  <tr key={index}>
                    <td className="hide-on-mobile">{index + 1}</td>
                    <td>{article.code}</td>
                    <td>{article.name}</td>
                    <td>
                      <div className="input-group quantity-buttons">
                        <div className="input-group-prepend">
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={() => {
                              const currentQty = Number(currentQuantity) || 0;
                              const value = removeQuantity(currentQty, article);
                              handleQuantityChange(index, value);
                            }}
                            disabled={
                              currentQuantity === "" ||
                              Number(currentQuantity) <= article.startingQuantityDemand
                            }
                          >
                            -
                          </button>
                        </div>
                        <input
                          type="number"
                          className="form-control input-width"
                          id={`quantity_${article.id}`}
                          required
                          name={`quantity_${article.id}`}
                          min={article.startingQuantityDemand}
                          value={currentQuantity}
                          onChange={(e) => {
                            const value = e.target.value;
                            const updatedBasketItems = basketItems.map((basketItem, i) => {
                              if (i === index) {
                                return { ...basketItem, quantity: value };
                              }
                              return basketItem;
                            });
                            setBasketItems(updatedBasketItems);
                          }}
                          onBlur={(e) => {
                            const value = e.target.value;
                            let adjustedQuantity;
                            if (value === "") {
                              adjustedQuantity = "";
                            } else {
                              adjustedQuantity = getValidQuantity(
                                value,
                                article.startingQuantityDemand,
                                article.minimumQuantityDemand
                              );
                            }
                            const updatedBasketItems = basketItems.map((basketItem, i) => {
                              if (i === index) {
                                return { ...basketItem, quantity: adjustedQuantity };
                              }
                              return basketItem;
                            });
                            setBasketItems(updatedBasketItems);
                          }}
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={() => {
                              const currentQty = Number(currentQuantity) || 0;
                              const value = addQuantity(currentQty, article);
                              handleQuantityChange(index, value);
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </td>
                    <td className="hide-on-mobile">{formatNumberKG(article.brutoMass * item.quantity)}</td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => removeBasketItem(article)}
                      >
                        Ukloni
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="12" className="text-center">Korpa je prazna.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <textarea
        className="block w-full px-4 py-2 mt-1 border rounded-lg text-gray-700 bg-gray-50 border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Napomena..."
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <br />
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <strong className="text-lg text-gray-700">Ukupna Težina :</strong>
            <span className="text-lg text-gray-900 font-bold">{formatNumberWithoutPostfix(totalWeight)} KG</span>
          </div>
        </div>
      </div>
      <br />
      {isDeliveryAddressEditable ? (
        <select
          className="block w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-base text-gray-700"
          value={JSON.stringify(deliveryAddress)}
          onChange={handleDeliveryAddressChange}
        >
          <option key="0" value="">Izaberite Adresu Dostave</option>
          {loggedInClient && loggedInClient.deliveryAddressList && loggedInClient.deliveryAddressList.map((addr, index) => {
            return (
              <option key={index + 1} value={JSON.stringify(addr)}>
                {addr.city}, {addr.address}
              </option>
            );
          })}
        </select>
      ) : (
        <div>
          <label className="block text-gray-700">Adresa Dostave:</label>
          <p>{deliveryAddress.city}, {deliveryAddress.address}</p>
        </div>
      )}
      <br />
      <div>
        <label className="block text-gray-700">Tip Plaćanja:</label>
        <select
          className="block w-full mt-1 bg-white border rounded-lg"
          value={paymentType}
          onChange={(e) => setPaymentType(e.target.value)}
          required
        >
          <option value="">Izaberite Tip Plaćanja</option>
          <option value="faktura">Faktura</option>
          <option value="gotovina">Gotovina</option>
          <option value="gotovinski racun">Gotovinski račun</option>
        </select>
      </div>
      <br />
      {(!deliveryAddress || !paymentType) && Object.keys(missingItemQuantities).length === 0 && basketItems.length > 0 && (
        <div className="alert alert-warning" role="alert">
          {!deliveryAddress && <p>Morate izabrati <strong>adresu dostave</strong> kako bi aktivirali narudžbinu!</p>}
          {!paymentType && <p>Morate izabrati <strong>tip plaćanja</strong> kako bi aktivirali narudžbinu!</p>}
        </div>
      )}
      {Object.keys(missingItemQuantities).length !== 0 && (
        <div className="alert alert-warning" role="alert">
          {Object.entries(missingItemQuantities).map(([articleName, { article, missingQuantity }]) => (
            <p key={articleName}>
              {articleName === 'NOCCO'
                ? <>Za brend <strong>{articleName}</strong>, broj poručenih komada mora biti deljiv sa 24. Možete dodati još <strong>{missingQuantity}</strong> komada u korpu da bi ispunili uslov.</>
                : <>Za artikal <strong>{articleName}</strong>, količina mora početi od <strong>{article.startingQuantityDemand}</strong> i povećavati se za <strong>{article.minimumQuantityDemand}</strong>. Možete dodati još <strong>{missingQuantity}</strong> komada u korpu da bi ispunili uslov.</>
              }
            </p>
          ))}
        </div>
      )}
      <button
        className="btn btn-success"
        onClick={confirmOrder}
        disabled={
          !deliveryAddress ||
          !paymentType ||
          basketItems.length === 0 ||
          !allItemsHaveValidQuantities
        }
      >
        Potvrdi porudžbinu
      </button>
    </div>
  );
};

export default Basket;
