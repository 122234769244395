import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTable } from 'react-table';
import ArticleDataService from '../services/ArticleService';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import log from "../services/logger";

const ArticleTable = () => {
  const [articles, setArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    retrieveArticles();
  }, []);

  const retrieveArticles = useCallback(() => {
    ArticleDataService.getAll()
      .then((response) => {
        // Group the data by brandName
        const groupedData = response.data.reduce((groups, article) => {
          const brandName = article.brand.brandName;

          if (!groups[brandName]) {
            groups[brandName] = [];
          }

          groups[brandName].push(article);
          return groups;
        }, {});

        // Sort each group by name
        for (const brandName in groupedData) {
          groupedData[brandName].sort((a, b) => a.name.localeCompare(b.name));
        }

        // Flatten the grouped data back into an array
        const sortedAndGroupedData = Object.values(groupedData).flat();

        setArticles(sortedAndGroupedData);
        log.info(sortedAndGroupedData);
      })
      .catch((e) => {
        log.error(e);
      });
  }, []); // Added empty dependency array to prevent unnecessary re-rendering

  const addArticle = useCallback(() => {
    navigate(`/articles/add`);
  }, [navigate]);

  const handleSearchChange = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  const editArticle = useCallback((id) => {
    //log.info(`Editing ARTICLE ID: ${id}`);
    navigate(`/articles/edit/${id}`);
  }, [navigate]);

  const toggleArticleVisibility = useCallback((id, actionType) => {
    const action = actionType === 'show' ? ArticleDataService.showArticle : ArticleDataService.hideArticle;
    action(id)
      .then(() => {
        retrieveArticles();
        toast.success(`Uspešno promenjena vidljivost artikla!`);
      })
      .catch(e => {
        log.error(e);
        toast.error('Neuspešna promena vidljivosti artikla!');
      });
  }, [retrieveArticles]);

  const toggleArticleStatus = useCallback((id, actionType) => {
    const action = actionType === 'activate' ? ArticleDataService.activateArticle : ArticleDataService.deactivateArticle;
    action(id)
      .then(() => {
        retrieveArticles();
        toast.success(`Artikal je ${actionType === 'activate' ? 'aktiviran' : 'deaktiviran'}!`);
      })
      .catch(e => {
        log.error(e);
        toast.error(`Neuspešna ${actionType === 'activate' ? 'aktivacija' : 'deaktivacija'} artikla!`);
      });
  }, [retrieveArticles]);

  const filteredArticles = useMemo(() => {
    return articles.filter(article =>
      article.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [articles, searchTerm]);

  const columns = useMemo(() => [
    {
      Header: 'Šifra Artikla',
      accessor: 'code',
    },
    {
      Header: 'GTIN',
      accessor: 'barcode',
    },
    {
      Header: 'Naziv',
      accessor: 'name',
    },
    {
      Header: 'Jedinica Mere',
      accessor: 'unitOfMeasurement',
    },
    {
      Header: 'Brend',
      accessor: 'brand.brandName',
    },
    {
      Header: 'Fakturna Cena',
      accessor: 'wholesalePrice',
      Cell: ({ value }) => (
        <span>{Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} RSD</span>
      ),
    },
    {
      Header: 'Stopa PDV-a',
      accessor: 'pdv',
      Cell: ({ value }) => <span>{value}%</span>,
    },
    {
      Header: 'Broj Komada U Paketu',
      accessor: 'quantityPerTransportPackage',
    },
    {
      Header: 'Minimum Za Trebovanje',
      accessor: 'minimumQuantityDemand',
    },
    {
      Header: 'Bruto Težina Proizvoda (KG)',
      accessor: 'brutoMass',
      Cell: ({ value }) => `${value} KG`,
    },
    {
      Header: 'Status',
      accessor: 'isActive',
      Cell: ({ value }) => (value ? 'Aktivan' : 'Neaktivan'),
    },
    {
      Header: 'Maloprodajna Cena',
      accessor: 'retailPrice',
      Cell: ({ value }) => (
        <span>{Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} RSD</span>
      ),
    },
    {
      Header: 'Akcije',
      accessor: 'actions',
      Cell: (props) => {
        const articleID = props.row.original.article_id;
        const isActive = props.row.original.isActive;
        const isVisible = props.row.original.isVisible;
        return (
          <div className="flex space-x-2">
            <button onClick={() => editArticle(articleID)} className="px-1 py-1 bg-blue-500 text-white rounded">
              <i className="fas fa-edit mr-2"></i>Izmeni
            </button>
            {/* {isActive ? (
              <button
                onClick={() => toggleArticleStatus(articleID, 'deactivate')}
                className="px-2 py-1 bg-yellow-500 text-white rounded"
              >
                Deaktiviraj
              </button>
            ) : (
              <button
                onClick={() => toggleArticleStatus(articleID, 'activate')}
                className="px-2 py-1 bg-green-500 text-white rounded"
              >
                Aktiviraj
              </button>
            )}
            {isVisible ? (
              <button
                onClick={() => toggleArticleVisibility(articleID, 'hide')}
                className="px-2 py-1 bg-red-500 text-white rounded"
              >
                Sakrij
              </button>
            ) : (
              <button
                onClick={() => toggleArticleVisibility(articleID, 'show')}
                className="px-2 py-1 bg-green-800 text-white rounded"
              >
                Prikaži
              </button>
            )} */}
          </div>
        );
      },
    },
  ], [editArticle, toggleArticleStatus, toggleArticleVisibility]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: filteredArticles });

  return (
    <div className="overflow-x-auto">
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Unesite naziv artikla radi pretrage..."
        className="mb-4 p-2 border border-gray-300 rounded"
      />
      <table {...getTableProps()} className="min-w-full">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} className="px-4 py-2 border-b border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps({
                style: {
                  backgroundColor: row.original.isActive ? 'white' : '#FFD1D1',
                }
              })}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} className="px-4 py-2 border-b border-gray-300 text-sm leading-5 text-gray-900">
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <button onClick={addArticle} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
        <i className="fas fa-plus mr-2"></i> Dodaj Artikal
      </button>
      <ToastContainer />
    </div>
  );
};

export default ArticleTable;
