export function formatDate(timestamp) {
  const date = new Date(timestamp);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
}

// utils.js

// Function to format numbers with two decimal places
export const formatNumberPlain = (number) => {
  return number.toFixed(2); // Returns a string
};

// Function to format weights with two decimal places and 'kg' suffix
export const formatNumberKGPlain = (number) => {
  return `${number.toFixed(2)} kg`; // Returns a string
};



export const formatNumberKG = (number) => {
  return `${number.toLocaleString('sr-RS', { minimumFractionDigits: 2 })} kg`;
};


export function getAllowedRoles() {
  return ["ROLE_ADMIN", "ROLE_USER", "ROLE_MODERATOR", "ROLE_FAKTURISTA", "ROLE_MAGACIONER"];
}

export const phoneNumberRegEx = /^\+\d{3}\d{9}$/;

export function formatNumber (number) {
  return <span>{Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} RSD</span>
}

export function discountedPrice (price, discount) {
  return (Number(price) * (1 - Number(discount / 100)).toFixed(2));
}

export function priceWithPDV (price, discount) {
  return (Number(price) * (1 + Number(discount / 100)).toFixed(2));
}

    
export function formatNumberWithoutPostfix (number) {
  return <span>{Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
}

export function addQuantity(currentQuantity, article){
  const currentQty = Number(currentQuantity) || 0;
  const start = article.startingQuantityDemand;
  const min = article.minimumQuantityDemand;

  let value;
  if (start === min) {
    // If starting and minimum are the same
    if (currentQty < start) {
      value = start;
    } else {
      value = Math.round(currentQty + min);
    }
  } else {
    // Original logic if starting and minimum are different
    if (currentQty < start) {
      value = start;
    } else if (currentQty === start) {
      value = min;
    } else {
      value = Math.round(currentQty + min);
    }
  }
  return value;
}

export function removeQuantity(currentQuantity, article){
  const currentQty = Number(currentQuantity) || 0;
  if (currentQty <= article.startingQuantityDemand) {
    return;
  }
  const value =
    currentQty - article.minimumQuantityDemand;
  return value;
}

export const removeSpecialCharacters = (text) => {
  if (!text) return text;
  const specialChars = {
    'š': 's',
    'Š': 'S',
    'đ': 'dj',
    'Đ': 'Dj',
    'ž': 'z',
    'Ž': 'Z',
    'č': 'c',
    'Č': 'C',
    'ć': 'c',
    'Ć': 'C',
    '_': ' ',
  };
  return text.replace(/[šŠđĐžŽčČćĆ_]/g, (match) => specialChars[match] || match);
};
