import React from "react";
import { useUserLogin } from "../components/hooks/useUserLogin";
import "./Login.css";
import Credits from "./Credits";

const Login = () => {
  const {
    username,
    setUsername,
    password,
    setPassword,
    error,
    handleLogin,
  } = useUserLogin();

  return (
    <div className="login-container">
      <div className="login-form">
        <h1>Prijava na Zar Mance Portal</h1>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="username">Korisničko ime</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Unesite korisničko ime" // "Enter username"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Šifra</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Unesite šifru" // "Enter password"
            />
          </div>
          <button type="submit">Potvrdi</button>
        </form>
        <Credits/>
      </div>
    </div>
  );
};

export default Login;
