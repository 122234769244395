import axios from "axios";
import EventEmitter from 'events';
import log from "./logger";

export const eventEmitter = new EventEmitter();

// Use the environment variable with a fallback if not defined
const API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const setAuthToken = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    /* log.info("Token set:", token); */
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
};

let token;
try {
  const user = JSON.parse(localStorage.getItem("user") || '{}');
  token = user?.accessToken;
} catch (error) {
  console.error("Error parsing localStorage user data:", error);
  token = null;
}

if (token) {
  setAuthToken(token);
}

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      setAuthToken(null);
      eventEmitter.emit('unauthorized');
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
