import React, { useState, useEffect, useContext } from "react";
import ArticleDataService from "../services/ArticleService";
import logo from "./../images/logo.jpg";
import { ApplicationContext } from "./ApplicationContext";
import "./ShopComponent.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Credits from "./Credits";
import log from "../services/logger";
import {
  addQuantity,
  removeQuantity
} from './utils';

const images = require.context("./../images/", true);

const ShopComponent = (props) => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const { loggedInClient, addOrUpdateBasketItem } =
    useContext(ApplicationContext);
  const [quantities, setQuantities] = useState({});

  useEffect(() => {
    retrieveArticles();
  }, []);

  const retrieveArticles = () => {
    ArticleDataService.getAll()
      .then((response) => {
        const allArticles = response.data;
        const articles = allArticles.filter(
          (article) => article.isVisible === true
        );

        // First, sort articles by ID treating ID as a number
        articles.sort((a, b) => Number(a.id) - Number(b.id));

        // Next, group sorted articles by brandName
        let groups = articles.reduce((acc, article) => {
          const brandName = article.brand.brandName;
          if (!acc[brandName]) {
            acc[brandName] = [];
          }
          acc[brandName].push(article);
          return acc;
        }, {});

        // Flatten the grouped articles to get the final sorted list
        const sortedArticles = Object.keys(groups)
          .sort()
          .flatMap((brandName) => groups[brandName]);

        setArticles(sortedArticles);
        setFilteredArticles(sortedArticles);
        setBrands(Object.keys(groups).sort());
      })
      .catch((e) => {
        log.info(e);
      });
  };

  const addToBasket = (article, quantity) => {
    if (
      quantity > 0 &&
      isValidQuantity(
        quantity,
        article.startingQuantityDemand,
        article.minimumQuantityDemand
      )
    ) {
      addOrUpdateBasketItem(article, quantity);
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [article.article_id]: "",
      }));
      toast.success("Uspešno dodavanje artikla " + article.name + " u korpu!");
    } else {
      toast.error("Uneta količina nije validna.");
    }
  };

  const brandDiscount = (brand) => {
    const brandName = brand.brandName;
    const discountForTheBrand = loggedInClient?.discounts?.find(
      (item) => item.brand.brandName === brandName
    );
    if (discountForTheBrand) {
      return discountForTheBrand.discount;
    } else {
      return 0;
    }
  };

  const handleBrandFilterChange = (brandName) => {
    setSelectedBrand(brandName);
    if (brandName) {
      if (brandName === "ACTIVE PHARMA") {
        setFilteredArticles(
          articles.filter((article) =>
            article.brand.brandName.startsWith(brandName)
          )
        );
      } else {
        setFilteredArticles(
          articles.filter((article) => article.brand.brandName === brandName)
        );
      }
    } else {
      setFilteredArticles(articles);
    }
  };

  const numberFormatter = new Intl.NumberFormat("sr-RS", {
    style: "currency",
    currency: "RSD",
  });

  const discountedPrice = (price, discount) => {
    return (Number(price) * (1 - Number(discount / 100))).toFixed(2);
  };

  const handleQuantityChange = (id, value) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: value,
    }));
  };

  const handleValueValidation = (
    id,
    value,
    startingQuantityDemand,
    minimumQuantityDemand
  ) => {
    let newValue = value;
    if (value === "") {
      newValue = "";
    } else {
      newValue = getValidQuantity(
        value,
        startingQuantityDemand,
        minimumQuantityDemand
      );
    }

    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: newValue,
    }));
  };

  function isValidQuantity(value, s, m) {
    value = Number(value);
    if (isNaN(value) || value < s) {
      return false;
    }

    const tolerance = 0.001;

    // Check if value is equal to starting quantity
    if (Math.abs(value - s) < tolerance) {
      return true;
    }

    // Check if value is divisible by minimum quantity
    if (value % m < tolerance || Math.abs((value % m) - m) < tolerance) {
      return true;
    }

    return false;
  }

  function getValidQuantity(value, s, m) {
    value = Number(value);

    if (isNaN(value) || value < s) {
      return s;
    }

    if (Math.abs(value - s) < 0.001) {
      return s;
    }

    if (
      value >= s &&
      (value % m < 0.001 || Math.abs((value % m) - m) < 0.001)
    ) {
      return value;
    }

    // Adjust upwards to the next multiple of m
    const n = Math.ceil(value / m);
    const newValue = n * m;

    if (newValue < s) {
      return s;
    }

    return Number(newValue.toFixed(2));
  }

  function importAll(r) {
    r.keys().forEach((item) => {
      images[item.replace("./", "")] = r(item);
    });
  }
  importAll(require.context("../images", false, /\.(png|jpe?g|svg)$/));

  const findArticleImage = (code) => {
    if (images[`${code}.jpg`]) {
      return images[`${code}.jpg`];
    } else if (images[`${code}.png`]) {
      return images[`${code}.png`];
    } else {
      return logo;
    }
  };

  const formatExpiryTime = (minutes) => {
    const hrs = Math.floor(minutes / 60);
    const mins = minutes % 60;
    let result = "";
    if (hrs > 0) {
      result += `${hrs} h `;
    }
    if (mins > 0) {
      result += `${mins} min`;
    }
    return result.trim();
  };

  return (
    <div className="overflow-x-auto">
      <div className="filter-container">
        <div className="brand-filter">
          <h5>Filtriranje po brendu</h5>
          <select
            className="form-select my-1"
            value={selectedBrand}
            onChange={(e) => handleBrandFilterChange(e.target.value)}
          >
            <option value="">Svi brendovi</option>
            {brands.map((brandName, index) => {
              return (
                <option key={index} value={brandName}>
                  {brandName}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="shop-container mt-5">
        {filteredArticles.map((article) => {
          const currentQuantity = quantities[article.article_id] || "";
          const finalPriceWithDiscountForCustomer = discountedPrice(
            article.wholesalePrice,
            brandDiscount(article.brand)
          );

          return (
            <div
              key={article.article_id}
              className={`shop-card ${
                !article.isActive ? "inactive-card" : ""
              }`}
              style={{ maxWidth: "250px" }}
            >
              <LazyLoadImage
                src={findArticleImage(article.code)}
                alt={article.name}
                className="img-fluid styleShopImage"
              />
              <div>
                <div className="hide-on-mobile">
                  <div className="mb-2">
                    <label className="block mb-1">ID</label>
                    <h6>{article.code}</h6>
                  </div>
                </div>
                <div className="h-20 flex flex-col mb-1">
                  <label className="block mb-1">Artikal</label>
                  <h6 className="h-12 truncate-2-lines">{article.name}</h6>
                </div>
                <div className="hide-on-mobile">
                  <div className="mb-2">
                    <label className="block mb-1">Transportno Pakovanje</label>
                    <h6>
                      {article.quantityPerTransportPackage}{" "}
                      {article.unitOfMeasurement}
                    </h6>
                  </div>
                  {/* <div className="mb-2">
                    <label className="block mb-1">Minimalna Količina</label>
                    <h6>
                      {article.startingQuantityDemand}{" "}
                      {article.unitOfMeasurement}
                    </h6>
                  </div> */}
                  {/* <div>
                    <label className="block mb-1">Rok trajanja</label>
                    <h6 className="block mb-2">
                      {formatExpiryTime(article.expiryDateInMinutes)}
                    </h6>
                  </div> */}
                </div>
                {/* <div className="mb-2">
                  <label className="block mb-1">
                    Čuvati na temperaturi
                  </label>
                  <h6>
                    {article.minimumTemperature} do{" "}
                    {article.maximumTemperature} °
                  </h6>
                </div> */}
                {/* Conditionally render input group or "Nedostupno" label */}
                {!article.isActive ? (
                  <div className="inactive-label">
                    <span>Nedostupno</span>
                  </div>
                ) : (
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={() => {
                          const currentQty = Number(currentQuantity) || 0;
                          const value = removeQuantity(currentQty, article);
                          handleQuantityChange(
                            article.article_id,
                            value.toString()
                          );
                          handleValueValidation(
                            article.article_id,
                            value,
                            article.startingQuantityDemand,
                            article.minimumQuantityDemand
                          );
                        }}
                        disabled={
                          currentQuantity === "" ||
                          Number(currentQuantity) <=
                            article.startingQuantityDemand
                        }
                      >
                        -
                      </button>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id={`quantity_${article.article_id}`}
                      required
                      name={`quantity_${article.article_id}`}
                      min={article.startingQuantityDemand}
                      value={currentQuantity}
                      onChange={(e) =>
                        handleQuantityChange(article.article_id, e.target.value)
                      }
                      onBlur={(e) =>
                        handleValueValidation(
                          article.article_id,
                          e.target.value,
                          article.startingQuantityDemand,
                          article.minimumQuantityDemand
                        )
                      }
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={() => {
                          const currentQty = Number(currentQuantity) || 0;
                          let value = addQuantity(currentQty, article);
                          handleQuantityChange(
                            article.article_id,
                            value.toString()
                          );
                          handleValueValidation(
                            article.article_id,
                            value,
                            article.startingQuantityDemand,
                            article.minimumQuantityDemand
                          );
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                )}
                {article.isActive && (
                  <button
                    onClick={() => {
                      addToBasket(article, currentQuantity);
                    }}
                    className="btn btn-success"
                    disabled={
                      !currentQuantity ||
                      !isValidQuantity(
                        currentQuantity,
                        article.startingQuantityDemand,
                        article.minimumQuantityDemand
                      )
                    }
                  >
                    Dodaj
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <Credits />
    </div>
  );
};

export default ShopComponent;
