import log from 'loglevel';

// Set log level based on the environment
log.setLevel(process.env.NODE_ENV === 'production' ? 'warn' : 'debug');

// Direct logging functions for each level, without recursion
const logInfo = (message) => {
  try {
    if (typeof message === 'object') {
      log.info(message?.message || '[Object details omitted]');
    } else {
      log.info(message);
    }
  } catch (e) {
    console.error('Logging failed:', e.message);
  }
};

const logError = (message) => {
  try {
    if (typeof message === 'object') {
      log.error(message?.message || '[Object details omitted]');
    } else {
      log.error(message);
    }
  } catch (e) {
    console.error('Logging failed:', e.message);
  }
};

const logWarn = (message) => {
  try {
    if (typeof message === 'object') {
      log.warn(message?.message || '[Object details omitted]');
    } else {
      log.warn(message);
    }
  } catch (e) {
    console.error('Logging failed:', e.message);
  }
};

const logDebug = (message) => {
  try {
    if (typeof message === 'object') {
      log.debug(message?.message || '[Object details omitted]');
    } else {
      log.debug(message);
    }
  } catch (e) {
    console.error('Logging failed:', e.message);
  }
};

// Export individual log level functions
export default {
  info: logInfo,
  error: logError,
  warn: logWarn,
  debug: logDebug,
};
