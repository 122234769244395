import axiosInstance from './ApiService';
import log from "../services/logger";

const changePassword = async (oldPassword, newPassword, userName) => {
    try {
        const requestData = {
            oldPassword,
            newPassword,
            userName
        };
        const response = await axiosInstance.put('korisnik/promeniSifru', requestData);
        log.info(`Password changed successfully for user: ${userName}`);
        return response.data;
    } catch (error) {
        if (error.response) {
            // Log only relevant parts, not the whole response object
            log.error(`Error: ${error.response.status} - ${error.response.data?.message || 'No message available'}`);
            handlePasswordChangeError(error.response.status);
        } else if (error.request) {
            log.error('No response received from server');
            alert('Nema odgovora od servera');
        } else {
            log.error(`Error during request: ${error.message}`);
            alert('Došlo je do greške prilikom postavljanja zahteva');
        }
        return null;
    }
};

// Extracted error handling to avoid redundant logs
const handlePasswordChangeError = (status) => {
    if (status === 404) {
        alert('Korisnik nije pronađen');
    } else if (status === 412) {
        alert('Stara lozinka se ne poklapa');
    } else {
        alert('Došlo je do greške prilikom promene lozinke');
    }
};

const UserService = {
    changePassword,
};

export default UserService;
