import axiosInstance from './ApiService';
import log from "../services/logger";

const getAll = () => {
  return axiosInstance.get("rabat");
};

const get = (id) => {
  return axiosInstance.get(`rabat/${id}`);
};

const create = (data) => {
  return axiosInstance.post("rabat", data);
};

const createCustomerDiscount = async (customerId, brandName, discount) => {
  log.info("customerId:" + customerId);
  log.info("brandName:" + brandName);
  log.info("discount:" + discount);
  try {
    const response = await axiosInstance.post('rabat', {
      customerId: Number(customerId),
      brandName,
      discount: Number(discount),
    });
    log.info(response);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const updateCustomerDiscount = async (customerId, brandName, discount) => {
  log.info("customerId:" + customerId);
  log.info("brandName:" + brandName);
  log.info("discount:" + discount);
  try {
    const response = await axiosInstance.put('rabat', {
      customerId: Number(customerId),
      brandName,
      discount: Number(discount),
    });
    log.info(response);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const deleteCustomerDiscount = async (customerId, brandName) => {
  log.info("customerId:" + customerId);
  log.info("brandName:" + brandName);
  try {
    const response = await axiosInstance.delete('rabat', {
      data: {
        customerId: Number(customerId),
        brandName,
        discount: Number(0),
      },
    });
    log.info(response);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const findClientsDiscounts = async () => {
  try {
    const response = await axiosInstance.get(`rabat/client`);
    log.info('Service Response:', response.data); // Added for debugging
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const findDiscountsOfSpecificClient = async (customerId) => {
  try {
    const response = await axiosInstance.get(`rabat/selectedClient/${customerId}`);
    log.info('Service Response:', response.data); // Added for debugging
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};


const update = (id, data) => {
  return axiosInstance.put(`rabat/${id}`, data);
};

const remove = (id) => {
  return axiosInstance.delete(`rabat/${id}`);
};

const removeAll = () => {
  return axiosInstance.delete("rabat");
};

const BrandService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  createCustomerDiscount,
  findClientsDiscounts,
  findDiscountsOfSpecificClient,
  updateCustomerDiscount,
  deleteCustomerDiscount,
};

export default BrandService;
