import React, { useState, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { ApplicationContext } from "./ApplicationContext";
import { useLogout } from "./useLogout";

const NavbarComponent = () => {
  const [open, setOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { userRole, userName, basketItems } = useContext(ApplicationContext);
  const logoutFunction = useLogout();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav className="bg-red-900 text-white shadow-lg">
      <div className="container mx-auto px-4 py-3 flex items-center justify-between">
        <Link to="/" className="flex items-center text-2xl font-semibold tracking-wide hover:text-red-400">
          <svg className="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M12 2a9 9 0 0 1 9 9c0 5.25-4.5 9-9 9s-9-3.75-9-9a9 9 0 0 1 9-9zm0 2a7 7 0 0 0-7 7c0 4.14 3.36 7 7 7s7-2.86 7-7a7 7 0 0 0-7-7zm2 4a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm-1 4c.28.28.28.72 0 1l-3 3a.7.7 0 0 1-1 0 .7.7 0 0 1 0-1l3-3c.28-.28.72-.28 1 0z" />
          </svg>
          Zar Mance Portal
        </Link>

        <div className="hidden md:flex space-x-4">
          {userRole === "ROLE_ADMIN" && (
            <>
              <NavLink to="/shop" className="hover:text-red-300">Shop</NavLink>
              <NavLink to="/articles" className="hover:text-red-300">Artikli</NavLink>
              <NavLink to="/clients" className="hover:text-red-300">Klijenti</NavLink>
              <NavLink to="/brands" className="hover:text-red-300">Brendovi</NavLink>
              {/* <NavLink to="/statistics" className="hover:text-red-300">Statistika</NavLink> */}
              <NavLink to="/indents" className="hover:text-red-300">Porudzbine</NavLink>
              <NavLink to="/basket" className="hover:text-red-300">
                Korpa {basketItems && basketItems.length > 0 ? `(${basketItems.length})` : ""}
              </NavLink>
            </>
          )}
          {userRole === "ROLE_USER" && (
            <>
              <NavLink to="/shop" className="hover:text-red-300">Shop</NavLink>
              <NavLink to="/indents" className="hover:text-red-300">Porudzbine</NavLink>
              {/* <NavLink to="/statistics" className="hover:text-red-300">Statistika</NavLink> */}
              <NavLink to="/basket" className="hover:text-red-300">
                Korpa {basketItems && basketItems.length > 0 ? `(${basketItems.length})` : ""}
              </NavLink>
            </>
          )}
          {userRole === "ROLE_FAKTURISTA" && (
            <>
              <NavLink to="/clients" className="hover:text-red-300">Klijenti</NavLink>
              <NavLink to="/indents" className="hover:text-red-300">Porudzbine</NavLink>
            </>
          )}          
          {userRole === "ROLE_MAGACIONER" && (
            <>
              <NavLink to="/indents" className="hover:text-red-300">Porudzbine</NavLink>
            </>
          )}
          {userRole === "ROLE_REGISTRATION" && (
            <>
              <NavLink to="/userManualRegistration" className="hover:text-red-300">Registracija Na Portal</NavLink>
            </>
          )}      
          <div className="relative group">
            <div className="flex items-center cursor-pointer" onClick={() => setOpen(!open)}>
              <span className="mr-2">Korisnički Profil</span>
              <svg className={`${open ? 'transform rotate-180' : ''} transition-transform duration-200`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="24" height="24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            {open && (
              <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <div className="py-1">
                  <div className="flex items-center px-4 py-2 text-gray-700"><strong className="ml-2">{userName}</strong></div>
                  <NavLink to="/userInfo" className="block px-4 py-2 text-red-600 hover:bg-gray-100">O profilu</NavLink>
                  <NavLink to="/change-password" className="block px-4 py-2 text-red-600 hover:bg-gray-100">Promeni Šifru</NavLink>
                  <NavLink onClick={logoutFunction} className="block px-4 py-2 text-red-600 hover:bg-gray-100">Odjavi se</NavLink>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="md:hidden" onClick={toggleMenu}>
          {/* Hamburger Icon */}
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </div>
      </div>
      <div className={`md:hidden ${showMenu ? "block" : "hidden"} p-4 bg-red-800`}>
        {/* Mobile Menu */}
        {userRole === "ROLE_ADMIN" && (
          <>
            <NavLink to="/shop" className="block py-2 hover:bg-red-700">Shop</NavLink>
            <NavLink to="/articles" className="block py-2 hover:bg-red-700">Artikli</NavLink>
            <NavLink to="/clients" className="block py-2 hover:bg-red-700">Klijenti</NavLink>
            <NavLink to="/brands" className="block py-2 hover:bg-red-700">Brendovi</NavLink>
            <NavLink to="/statistics" className="block py-2 hover:bg-red-700">Statistika</NavLink>
            <NavLink to="/indents" className="block py-2 hover:bg-red-700">Porudzbine</NavLink>
            <NavLink to="/basket" className="block py-2 hover:bg-red-700">
              Korpa {basketItems && basketItems.length > 0 ? `(${basketItems.length})` : ""}
            </NavLink>
          </>
        )}
        {userRole === "ROLE_USER" && (
          <>
            <NavLink to="/shop" className="block py-2 hover:bg-red-700">Shop</NavLink>
            <NavLink to="/indents" className="block py-2 hover:bg-red-700">Porudzbine</NavLink>
            <NavLink to="/statistics" className="block py-2 hover:bg-red-700">Statistika</NavLink>
            <NavLink to="/basket" className="block py-2 hover:bg-red-700">
              Korpa {basketItems && basketItems.length > 0 ? `(${basketItems.length})` : ""}
            </NavLink>
          </>
        )}
        {(userRole === "ROLE_FAKTURISTA" || userRole === "ROLE_MAGACIONER") && (
          <>
            <NavLink to="/indents" className="block py-2 hover:bg-red-700">Porudzbine</NavLink>
          </>
        )}
        {userRole === "ROLE_REGISTRATION" && (
          <>
            <NavLink to="/userManualRegistration" className="block py-2 hover:bg-red-700">Registracija Na Portal</NavLink>
          </>
        )}
        <div className="mt-4 bg-red-800 rounded-lg shadow-md">
          <div className="flex items-center justify-between px-4 py-3 border-b border-red-700">
            <strong className="text-white">{userName}</strong>
          </div>
          <div>
            <NavLink to="/userInfo" className="block px-4 py-2 text-red-300 hover:bg-red-700 hover:text-white">O profilu</NavLink>
            <NavLink to="/change-password" className="block px-4 py-2 text-red-300 hover:bg-red-700 hover:text-white">Promeni Šifru</NavLink>
            <NavLink onClick={logoutFunction} className="block px-4 py-2 text-red-300 hover:bg-red-700 hover:text-white">Odjavi se</NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavbarComponent;
