import { useState, useContext } from "react";
import { ApplicationContext } from "../ApplicationContext";
import { useNavigate } from "react-router-dom";
import axiosInstance, { setAuthToken } from "../../services/ApiService";

export const useUserLogin = (initialUsername = "", initialPassword = "", initialError = "") => {
  const [username, setUsername] = useState(initialUsername);
  const [password, setPassword] = useState(initialPassword);
  const [error, setError] = useState(initialError);
  const {
    setLoggedInClient,
    setAuthenticated,
    setUserRole,
    setUserName,
    setEmail,
  } = useContext(ApplicationContext);
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault(); // Prevents the default form submission behavior

    // Validation to check if username or password is empty
    if (!username || !password) {
      setError("Korisničko ime i šifra su obavezni."); // Set an error message
      return; // Stop the execution of the function if validation fails
    }

    setUserName(username); // Sets the username in the application context

    try {
      const response = await axiosInstance.post("auth/signin", {
        username,
        password,
      });

      if (response.data.accessToken) {
        const { accessToken, customer, roles } = response.data;
        localStorage.setItem("user", JSON.stringify(response.data)); // Store user data in localStorage
        setAuthToken(accessToken); // Set the auth token
        setUserRole(roles[0]); // Set the user role
        setAuthenticated(true); // Mark the user as authenticated

        if (roles[0] === "ROLE_FAKTURISTA" || roles[0] === "ROLE_MAGACIONER") {
          navigate("/indents");
        } else if (roles[0] === "ROLE_REGISTRATION") {
          setEmail(password); // password for registration prompt is the email of the customer
          navigate("/userManualRegistration");
        } else {
          setLoggedInClient(customer);
          navigate("/shop");
        }
      } else {
        setError("Netačni kredencijali");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(error.response.data); // Set the error message from the API response
      } else {
        setError("Greška prilikom prijavljivanja");
      }
    }
  };

  return {
    username,
    setUsername,
    password,
    setPassword,
    error,
    handleLogin, // Return the handleLogin function to be used by the component
  };
};
