// ImageService.js

const images = {};
function importAll(r) {
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
}
importAll(require.context("../images", false, /\.(png|jpe?g|svg)$/));

const findArticleImage = (code) => {
  if (images[`${code}.jpg`]) {
    return images[`${code}.jpg`];
  } else if (images[`${code}.png`]) {
    return images[`${code}.png`];
  } else {
    return "default-logo.png";
  }
};

const ImageService = {
  findArticleImage,
};

export default ImageService;