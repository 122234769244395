// InvoicePDF.js

import React from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import logo from "./../images/logo.jpg"; // Adjust the path if necessary
import {
  formatNumberPlain,
  formatNumberKGPlain,
  removeSpecialCharacters,
} from "./utils"; // Adjust the path if necessary

const InvoicePDF = ({
  indentCode,
  entries,
  indentType,
  customer,
  totalCost,
  totalWeight,
  totalNumberOfPackages,
  totalRealQuantity,
  comment,
}) => {
  const handlePrint = () => {
    const doc = new jsPDF();

    // Set the font to a standard font
    doc.setFont("Helvetica", "normal");

    // Page setup
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 2;
    const usablePageWidth = pageWidth - margin * 2;

    // Current date
    const currentDate = new Date().toLocaleDateString();

    // Header: "Faktura" and "Broj fakture" in one column, centered
    /* doc.setFontSize(16);
    doc.setFont("Helvetica", "bold");
    doc.text(
      removeSpecialCharacters("Faktura"),
      pageWidth / 2,
      20,
      null,
      null,
      "center"
    );
    doc.setFontSize(12);
    doc.setFont("Helvetica", "normal");
    doc.text(
      removeSpecialCharacters(`Broj fakture: ${indentCode}`),
      pageWidth / 2,
      28,
      null,
      null,
      "center"
    ); */

    // Below header, 3 columns
    const columnWidth = (usablePageWidth - 20) / 3; // Adjusted for spacing
    const columnSpacing = 10; // 10mm spacing between columns

    const col1X = margin;
    const col3X = margin + (columnWidth + columnSpacing) * 2;

    const startY = 5;

    // First column: Seller logo and info (name, PIB, MB)
    const SELLER_INFO = {
      name: removeSpecialCharacters("ŽAR MANCE PRERADA MESA DOO MARKOVAC"),
      pib: "111100045",
      mb: "21425761",
    };

    let currentY1 = startY;

    /* if (logo) {
      const imgWidth = columnWidth;
      const imgHeight = 30;
      doc.addImage(logo, "JPEG", col1X, currentY1, imgWidth, imgHeight);
      currentY1 += imgHeight + 5;
    } */

/*     doc.setFontSize(10);
    const sellerInfo = [
      `${SELLER_INFO.name}`,
      `PIB: ${SELLER_INFO.pib}`,
      `MB: ${SELLER_INFO.mb}`,
    ];
    sellerInfo.forEach((line) => {
      doc.text(line, col1X, currentY1);
      currentY1 += 5;
    }); */

    // Third column: Date of the order and "Nacin placanja"
    let currentY3 = startY+5;
    doc.setFontSize(10);
    doc.setFont("Helvetica", "bold");
    doc.text(
      removeSpecialCharacters(`Porudzbina: ${indentCode}`),
      10,
      currentY3
    );
    currentY3 += 7;
    doc.setFontSize(10);
    doc.text(
      removeSpecialCharacters(`Datum porudzbine: ${currentDate}`),
      10,
      currentY3
    );
    currentY3 = 10;

    doc.setFont("Helvetica", "bold");
    doc.text(removeSpecialCharacters("Nacin placanja:"), 140, currentY3);

    doc.setFontSize(10);
    doc.setFont("Helvetica", "normal");
    doc.text(
      removeSpecialCharacters(indentType || "Nije dostupno"),
      170,
      currentY3
    );
    currentY3 += 7;

    doc.setFontSize(10);
    doc.setFont("Helvetica", "bold");
    doc.text(removeSpecialCharacters("Kupac:"), 140, currentY3);
    /* currentY3 += 7; */

    doc.setFontSize(10);
    doc.setFont("Helvetica", "normal");
    doc.text(`${customer.nameOfTheLegalEntity}`, 155, currentY3);
    currentY3 += 7;

    // Determine the maximum Y position among the columns
    const currentY = Math.max(currentY1, currentY3);

    // Customer Information
/*     doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");
    doc.text(
      removeSpecialCharacters("Informacije o kupcu:"),
      margin,
      currentY + 10
    ); */
    let customerY = currentY;

/*     doc.setFontSize(10);
    doc.setFont("Helvetica", "normal"); */
    /* if (customer) {
      const customerInfo = [
        removeSpecialCharacters(`${customer.nameOfTheLegalEntity}`),
        removeSpecialCharacters(`Kontakt osoba: ${customer.contactPerson}`),
        removeSpecialCharacters(`Broj telefona: ${customer.contactNumber}`),
        removeSpecialCharacters(`Email: ${customer.email}`),
        removeSpecialCharacters(
          `Adresa: ${customer.address}, ${customer.city}`
        ),
      ];
      customerInfo.forEach((line) => {
        doc.text(line, margin, customerY);
        customerY += 5;
      });
    } else {
      doc.text(
        removeSpecialCharacters("Nema dostupnih informacija o kupcu."),
        margin,
        customerY
      );
      customerY += 5;
    } */

    // Table columns definition for rendering
    const columns = [
      { Header: removeSpecialCharacters("#"), accessor: "id" },
      { Header: removeSpecialCharacters("Artikal"), accessor: "article.name" },
      /*  { Header: removeSpecialCharacters('Cena'), accessor: 'articleWholeSalePrice' },
      { Header: removeSpecialCharacters('Valuta'), accessor: 'customer.paymentCurrency' }, */
/*       {
        Header: removeSpecialCharacters("Jedinica Mere"),
        accessor: "article.unitOfMeasurement",
      }, */
      {
        Header: removeSpecialCharacters("Kolicina"),
        accessor: "requestedQuantity",
      },
      /* { Header: removeSpecialCharacters('Rabat'), accessor: 'articleBrandDiscount' }, */
      /*  { Header: removeSpecialCharacters('Ukupna Cena'), accessor: 'finalPriceForGivenQuantity' },
      { Header: removeSpecialCharacters('Broj Paketa'), accessor: 'numberOfPackages' }, */
      {
        Header: removeSpecialCharacters("Tezina"),
        accessor: "articleWeightForGivenQuantity",
      },
      {
        Header: removeSpecialCharacters("Realna Tezina"),
        accessor: "realQuantity",
      },
    ];

    // Extract data for PDF
    const pdfColumns = columns.map((col) => ({
      header: col.Header,
      dataKey: col.Header,
    }));

    const pdfRows = entries.map((entry, index) => ({
      "#": (index + 1).toString(),
      Artikal: removeSpecialCharacters(entry.article.name),
      /*  Cena: formatNumberPlain(entry.articleWholeSalePrice), */
      Valuta: removeSpecialCharacters(entry.customer.paymentCurrency),
      /* "Jedinica Mere": removeSpecialCharacters(entry.article.unitOfMeasurement), */
      Kolicina: entry.requestedQuantity.toString(),
      /* Rabat: `${entry.articleBrandDiscount} %`, */
      /* 'Ukupna Cena': formatNumberPlain(entry.finalPriceForGivenQuantity), */
      /* 'Broj Paketa': entry.numberOfPackages.toString(), */
      Tezina: formatNumberKGPlain(entry.articleWeightForGivenQuantity),
      "Realna Tezina": null,
    }));

    // Function to split array into chunks
    const chunkArray = (array, chunk_size) => {
      const results = [];
      for (let i = 0; i < array.length; i += chunk_size) {
        results.push(array.slice(i, i + chunk_size));
      }
      return results;
    };

    // Split pdfRows into chunks of 11 rows
    const pdfRowChunks = chunkArray(pdfRows, 24);

    // Footer function to avoid code duplication
    const footer = (data) => {
      // Move footer down by 10 units
      const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from bottom

/*       // Center the footer content
      const footerText = `${SELLER_INFO.name}`;
      const footerTextWidth = doc.getTextWidth(footerText);
      const footerLogoWidth = 30; // Width of the logo
      const totalFooterWidth = footerLogoWidth + 5 + footerTextWidth; // 5 units spacing

      const footerX = (pageWidth - totalFooterWidth) / 2;
      const logoY = footerY - 15; // Adjust based on logo height

      if (logo) {
        const imgHeight = 15;
        doc.addImage(logo, "JPEG", footerX, logoY, footerLogoWidth, imgHeight);
      }

      doc.setFontSize(10);
      doc.setFont("Helvetica", "normal");
      doc.text(footerText, footerX + footerLogoWidth + 5, footerY - 5); */

      // Add page number on the far right side
      const pageNumber = doc.internal.getNumberOfPages();
      doc.text(
        `Strana ${pageNumber}`,
        pageWidth - margin,
        footerY - 5,
        null,
        null,
        "right"
      );
    };

    // Starting Y position for the table
    let tableStartY = customerY;

    // Loop through each chunk and create a table
    pdfRowChunks.forEach((chunk, index) => {
      if (index > 0) {
        doc.addPage();
        tableStartY = margin; // Reset Y position for new page
      }

      autoTable(doc, {
        startY: tableStartY,
        head: [pdfColumns.map((col) => col.header)],
        body: chunk.map((row) =>
          pdfColumns.map((col) => row[col.header] || "")
        ),
        styles: { fontSize: 12 },
        headStyles: {
          fillColor: [41, 128, 185],
          textColor: [255, 255, 255],
        },
        columnStyles: {
          0: { cellWidth: 10 }, // "#"
          // Adjust other columns as needed
        },
        margin: { left: margin, right: margin, bottom: 30 },
        didDrawPage: (data) => {
          footer(data);
        },
        pageBreak: "auto",
      });
    });

    // After the tables, add the totals and comments only on the last page
    const finalY = doc.lastAutoTable.finalY + 10;

    // Totals Section using autoTable
    const totalsData = [
      /* [removeSpecialCharacters('Ukupna Cena'), formatNumberPlain(totalCost)],
      [removeSpecialCharacters('Ukupno Paketa'), totalNumberOfPackages], */
      [removeSpecialCharacters("Ukupna Realna Tezina"), ""],
      [
        removeSpecialCharacters("Ukupna Porucena Tezina"),
        formatNumberKGPlain(totalWeight),
      ],
    ];

/*     autoTable(doc, {
      startY: finalY,
      head: [[removeSpecialCharacters("Ukupno"), ""]],
      body: totalsData,
      styles: { fontSize: 10 },
      headStyles: {
        fillColor: [200, 200, 200], // Light gray background
        textColor: [0, 0, 0], // Black text
        halign: "left", // Align header text to left
        fontSize: 12, // Increased font size
        fontStyle: "bold",
      },
      columnStyles: {
        0: { cellWidth: usablePageWidth * 0.6 },
        1: { cellWidth: usablePageWidth * 0.4, halign: "right" },
      },
      margin: { left: margin, right: margin },
      didDrawPage: (data) => {
        footer(data);
      },
    }); */

    const afterTotalsY = doc.lastAutoTable.finalY + 10;

    // Comment Section
    doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");
    doc.text(removeSpecialCharacters("Napomena:"), margin, afterTotalsY);
    doc.setFontSize(10);
    doc.setFont("Helvetica", "normal");
    const splitComment = doc.splitTextToSize(
      removeSpecialCharacters(comment || "Nema napomena."),
      usablePageWidth
    );
    doc.text(splitComment, margin, afterTotalsY + 7);

    // Save the PDF
    doc.save(`Faktura_${indentCode}.pdf`);
  };

  return (
    <button
      onClick={handlePrint}
      className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
    >
      Štampa
    </button>
  );
};

export default InvoicePDF;
