import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArticleService from "../services/ArticleService";
import ImageService from "../services/ImageService";
import CustomerService from "../services/CustomerService";
import { useLocation } from "react-router-dom";
import log from "../services/logger";

const ArticleVisibilityList = () => {
  const location = useLocation();
  const { clientID } = location.state || {};
  const [articles, setArticles] = useState([]);
  const [initialArticles, setInitialArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState([]);

  useEffect(() => {
    fetchClientArticles();
    retreiveClient(clientID);
  }, []);

  const retreiveClient = (id) => {
    CustomerService.get(id)
      .then((response) => {
        setCustomer(response.data);
        log.info(response.data);
      })
      .catch((e) => {
        log.info(e);
      });
  };

  const fetchClientArticles = async () => {
    try {
      const response = await ArticleService.getAllArticlesWithVisibilityForClient(clientID);
      const articlesWithVisibility = response.data.map((article) => ({
        ...article,
        showToCustomer: article.isVisible, // Initialize showToCustomer with isVisible value
      }));
      setArticles(articlesWithVisibility);
      setInitialArticles(articlesWithVisibility);
    } catch (error) {
      console.error("An error occurred while fetching articles:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (index) => {
    setArticles((prevArticles) => {
      const updatedArticles = prevArticles.map((article, i) =>
        i === index
          ? {
              ...article,
              showToCustomer: !article.showToCustomer,
              isVisible: !article.showToCustomer, // Toggle isVisible as well
            }
          : article
      );
      return updatedArticles;
    });
  };

  const confirmChanges = async () => {
    try {
      const articlesToHide = articles
        .filter((article) => !article.isVisible) // Ensure id exists
        .map((article) => article.article_id);

      if (articlesToHide.length === 0) {
        toast.info("No changes to save.");
        return; // Exit if there are no articles to hide
      }

      const requestData = {
        customerId: clientID,
        articlesToHide,
      };

      await ArticleService.hideArticlesForClient(requestData);
      toast.success("Changes have been saved successfully.");
      setInitialArticles(articles); // Reset initial articles after saving
    } catch (error) {
      console.error(
        "An error occurred while updating article visibility:",
        error
      );
      toast.error("Failed to save changes. Please try again later.");
    }
  };

  const hasChanges = () => {
    return JSON.stringify(articles) !== JSON.stringify(initialArticles);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      <ToastContainer />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h3 className="text-2xl font-bold leading-6 text-gray-900 mb-6">
          Lista artikala vidljiva klijentu "
          <u className="text-red-600">{customer.nameOfTheLegalEntity}</u>"
        </h3>

        <div className="overflow-x-auto bg-white rounded-lg shadow">
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-300">
                <th className="p-2 border">Slika</th>
                <th className="p-2 border">Naziv</th>
                <th className="p-2 border">Brend</th>
                <th className="p-2 border">Prikaži Kupcu</th>
              </tr>
            </thead>
            <tbody>
  {articles.map((article, index) => (
    <tr
      key={article.id}
      className={`hover:bg-gray-100 ${
        !article.isVisible ? "bg-red-100" : ""
      }`}
    >
      <td className="p-2 border">
        <img
          src={ImageService.findArticleImage(article.code)}
          alt={article.name}
          className="w-16 h-16 object-cover"
        />
      </td>
      <td className="p-2 border">{article.name}</td>
      <td className="p-2 border">
        {article.brandName ||
          (typeof article.brand === "string"
            ? article.brand
            : article.brand.brandName)}
      </td>
      <td className="p-2 border text-center">
        <input
          type="checkbox"
          checked={article.showToCustomer}
          onChange={() => handleCheckboxChange(index)}
        />
      </td>
    </tr>
  ))}
</tbody>

          </table>
        </div>
        <button
          onClick={confirmChanges}
          disabled={!hasChanges()}
          className={`mt-4 ${
            hasChanges() ? "bg-blue-500 hover:bg-blue-700" : "bg-gray-400"
          } text-white font-bold py-2 px-4 rounded-full`}
        >
          Potvrdi
        </button>
      </div>
    </div>
  );
};

export default ArticleVisibilityList;
